import styled, { device, css } from "@styled";

export const StyledSection = styled.section`
    padding-block-start: 100px;
    padding-block-end: 100px;
    background-color: #f7f7f7;

    .gatsby-image-wrapper {
        margin-left: 40px;

        @media (max-width: 991.98px) {
            margin-left: 0;
        }
    }

    .row {
        align-items: center;
    }

    h5 {
        font-size: 40px;
        letter-spacing: 0px;
        color: #212121;
        font-weight: bold;
        margin-bottom: 30px;
        line-height: 1.6;
    }
    ${device.medium} {
        padding-block-start: 80px;
        padding-block-end: 80px;
    }
    ${device.large} {
        padding-block-start: 60px;
        padding-block-end: 60px;
    }

    ${(props) =>
        props.layout === 2 &&
        css`
        padding-block-start: 80px;
        padding-block-end: 80px;

        .row {
            flex-direction: row-reverse;
        }
        
        ${device.medium} {
            padding-block-start: 100px;
            padding-block-end: 100px;
        }
        ${device.large} {
            padding-block-start: 110px;
            padding-block-end: 110px;
        }
    `}

    ${(props) =>
        props.layout === 3 &&
        css`
        padding-block-start: 100px !important;
        padding-block-end: 100px !important;
        // margin-bottom: -100px;
        
        ${device.medium} {
            padding-block-start: 110px !important;
            padding-block-end: 110px !important;
        }
        ${device.large} {
            padding-block-start: 120px !important;
            padding-block-end: 120px !important;
        }
    `}

    ${(props) =>
        props.layout === 4 &&
        css`
        padding-block-start: 100px !important;
        padding-block-end: 100px !important;
        background: #fff;
        ${device.medium} {
            padding-block-start: 110px !important;
            padding-block-end: 110px !important;
        }
        ${device.large} {
            padding-block-start: 120px !important;
            padding-block-end: 120px !important;
        }
    `}
`;